.fire-card-page {
	.dynamic {
		display: flex;
		gap: var(--fire-card-gap);

		.card {
			&.area-tenant,
			&.area {
				.card-content {
					padding: 30px 20px;
				}
			}
		}

		.squares-block {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			grid-template-rows: 1fr;
			grid-column-gap: 20px;

			.form-field {
				max-width: 70%;

				label {
					margin: 0;
				}
			}

			#box0,
			#box3,
			#box5 {
				border-color: var(--secondary);
			}

			#box8,
			#box10 {
				border-color: var(--contacted);
			}

			.box-color {
				&-blue {
					border-color: var(--secondary);
				}
				&-orange {
					border-color: var(--contacted);
				}
			}

			.box2 {
				grid-column-start: 4;
			}
			.box4 {
				grid-column-start: 3;
			}
			.box5 {
				grid-column-start: 1;
			}
			.box7 {
				grid-column-start: 4;
			}
			.box9 {
				grid-column-start: 3;
			}
			.box10 {
				grid-column-start: 1;
			}

			.ar-box2 {
				grid-column-start: 1;
			}
			.ar-box3 {
				grid-column-start: 3;
			}
			.ar-box5 {
				grid-column-start: 2;
			}
		}

		.connection-lines {
			position: relative;
		}
	}
}
