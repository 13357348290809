.temperature-tube-layer-popup {
	.layer-popup-measurements {
		display: flex;
		align-items: center;
	}
	.measurements-line {
		flex-grow: 1;
		border-bottom: 1px dotted #B8B8B8;
		margin: 5px 12px 0 12px;
	}
}
