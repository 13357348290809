.track-ship-popup {
    position: absolute;
    right: 10px;
    top: 80px;
    z-index: 1000;
    width: max-content;
    max-width: 80%;

    .ship-tab-content {
        height: unset;
    }
}