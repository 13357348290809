.adpiTitle {
	color: #7a7a7a;
	font-size: 16px;
	margin-bottom: 1em;
}

.adpi-form {
	max-width: 1200px;
}
.filterSelectsAdpi {
	display: flex;
	flex-wrap: wrap;
	margin-top: 1em;
}
