.ls5pso,
.list-112,
.statistic-112,
.techfaulty,
.lsogps40,
.fuel-report {
	width: 1080px;
	.filter {
		margin-bottom: 0 !important;
	}
	.sp-date-picker {
		max-width: 120px !important;
	}
}
.info-work-fire-protection-units {
	.form-field {
		max-width: 150px;
	}
}
.info-work-fire-protection-units-analysis,
.fuel-report {
	.form-field {
		max-width: 200px;
	}
}

.fuel-report {
	display: flex;
	gap: 35px;

	.fuel-report-count {
		display: flex;
		flex-direction: column;
		gap: 30px;
	}

	.firedep-name-list {
		padding-top: 20px;

		.fuel-report-list {
			background: var(--white);
			display: flex;
			width: 350px;
			justify-content: space-between;
			align-items: center;
			text-decoration: none;
			min-height: var(--row-height);
			color: var(--black);
			padding: 12px 24px;
			// font-size: 14px;

			&:hover {
				background: var(--dark-grey);
				color: var(--white);
			}

			.fuel-arrows {
				display: flex;
				gap: 10px;

				.cursor-pointer {
					display: block;
					width: 15px;
					height: 15px;
					line-height: 18px;
					background: #eee;
					text-align: center;
					color: var(--primary);
					border-radius: 5px;
				}
			}
		}
	}
}
