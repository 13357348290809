.card {
	background: var(--white);
	border: 1px solid rgba(#000, 0.15);
	box-shadow: 1px 3px 10px rgba(#000, 0.05);
	border-radius: 5px;

	&-title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1.5px solid var(--light-grey);
		height: 48px;
		padding: 12px 16px;

		svg {
			margin-right: 12px;

			*[fill] {
				fill: var(--primary);
			}

			*[stroke] {
				stroke: var(--primary);
			}
		}

		.icon-title {
			display: flex;
			flex-direction: row;
			align-items: center;

			h2 {
				margin-bottom: 0 !important;
			}
		}

		.card-actions {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 10px;
			cursor: pointer;

			button {
				background-color: transparent;
			}

			svg {
				*[stroke] {
					stroke: var(--lighter-dark);
				}

				transition: transform 0.3s ease-in-out;
			}

			.collapse-icon {
				&:not(.collapsed) {
					transform: rotate(180deg);
				}

				&:hover {
					*[stroke] {
						stroke: var(--primary);
					}
				}
			}
		}
	}

	&-content {
		padding: 16px;
		overflow: hidden;
		transition: transform 0.3s ease-in-out;

		&>div:last-child {
			&>div>.form-field {
				margin-bottom: 0 !important;
			}
		}
	}
}