.measurements-edit {
	.tubes-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 42px;

		h2 {
			margin-bottom: initial;
		}
	}

	.measurements-attachments {
		.attachments, .attachment, .upload {
			margin-bottom: 0 !important;
		}
	}

	.tubes-info {
		.label-field {
			color: var(--accent, #37b813);
		}
		margin-bottom: 48px;
	}

	.create-menu {
		display: flex;
		justify-content: center;
		padding: 5px 0;
		width: 100%;
		background: #f4f4f9;
	}

	.color-red {
		color: red;
	}
}

.measurements-count {
	font-weight: bold;
	height: 100% !important;
}
