.fire-card-page {
    .resources {
        display: flex;
        gap: var(--fire-card-gap);

        .left,
        .right {
            display: flex;
            gap: var(--fire-card-gap);
            flex-direction: column;
            width: 100%;
        }

        .left {
            max-width: var(--big-card-width);
        }
        .right {
            max-width: var(--small-card-width);
        }
        .increment {
            color: var(--primary) !important;
            font-weight: bold;
        }
    }
    
}
