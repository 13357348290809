.firedeps-section {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .add-firedep {
        display: flex;
        gap: 20px;
    }

    .firedeps-list {
        display: flex;
        flex-direction: column-reverse;
        gap: 20px
    }
}