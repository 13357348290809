.fire-card-page {
    --fire-card-gap: 10px;
    --big-card-width: 868px;
    --small-card-width: 656px;
    display: flex;
    gap: 20px;
    height: 100%;

    .new-fire {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;

        .custom-controls {
            margin-top: 0;
        }
    }

    .left,
    .right {
        display: flex;
        flex-direction: column;
        gap: var(--fire-card-gap);
        width: 100%;
    }

    .left {
        max-width: var(--big-card-width);
    }
    .right {
        max-width: var(--small-card-width);
    }

    .sp-tabs {
        height: 100% !important;
    }

    .sp-date-picker {
        min-width: unset !important;
    }

    .sp-row {
        .sp-col {
            margin-right: var(--fire-card-gap);
        }
        [class~="sp-col"]:last-of-type {
            margin-right: 0;
        }
    }
    // скрываем все кроме карты
    .sp-tabs {
        &.hidden {
            & > * {
                display: none;
            }
            .sp-tabs-content {
                display: block;
                .card-form {
                    overflow-y: unset !important;
                    .left {
                        display: none;
                    }
                    .right {
                        max-width: 100% !important;

                        & > * {
                            display: none;
                        }
                        .map-card {
                            display: block;
                            width: 100%;
                            height: 100%;
                            .card-title {
                                display: none;
                            }
                            .card-content {
                                padding: 0px;
                                .fire-card-map-container {
                                    height: calc(100vh - var(--header-height) - (var(--module-page-padding) * 2));
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
