.track-time-line {
    width: max-content;
    height: 52px;
    position: absolute;
    border-radius: 40px;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: white;
    display: flex;
    align-items: center;
    padding: 0 24px;
    gap: 10px;
    cursor: default;

    //
    justify-content: flex-end;

    .track-slider {
        height: 52px;
        display: flex;
        align-items: center;
        width: 30vw;

        &.no-tracks {
            justify-content: center;
        }

        .slider {
            margin: 0 40px;
            position: relative;
            width: 100%;
            height: 100%;
            cursor: grab;

            input {
                padding: 0;
            }
        }
    }

}