.operational-report-by-types {
	--dashboard-gap: 20px;
	.numbers-row {
		display: grid;
		grid-auto-flow: column;
		grid-auto-columns: 1fr;
		gap: 10px;

		.number-block {
			padding: 12px;
			background: var(--super-light-grey);
			border-radius: 5px;

			&.green {
				background: #dffbdb;
			}

			&.blue {
				background: #f2f6fc;
			}

			label {
				display: block;
				font-size: 12px;
				line-height: 14px;
				font-weight: 400;
				color: var(--dark-grey);
				margin-bottom: 10px;
				text-transform: uppercase;
			}

			.number-value {
				font-size: 24px;
				font-weight: 700;
			}
		}
	}

	table.dashboard-table {
		width: 100%;
		--height: 24px;

		th,
		td {
			padding: 0;
			color: var(--grey);
		}

		th {
			font-weight: 400;
			text-align: left;
			font-size: 14px;
			padding-bottom: 5px;
		}
		tr {
			td {
				font-size: 14px;

				&.category {
					text-transform: uppercase;
				}

				&.number {
					text-align: left;
					color: var(--dark);
					font-size: 14px;
					font-weight: 700;
				}
			}
		}
	}

	.main {
		display: flex;
		gap: var(--dashboard-gap);

		.left {
			flex-basis: 50%;
			flex-grow: 0;
			flex-shrink: 0;
			display: flex;
			flex-direction: column;
			gap: var(--dashboard-gap);

			.dynamics {
				min-height: 645px;
			}
		}

		.right {
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			gap: var(--dashboard-gap);

		}
	}

	@media (min-width: 768px) and (max-width: 1199px) {
		.main {
			display: initial;

			.right {
				margin-bottom: 10px;
			}
		}
	}

	.number-value {
		&.red {
			color: #d00;
		}
	}

	.fires-count {
		.fires-count-chart {
			canvas {
				width: 100% !important;
				height: 100% !important;
			}
		}

		.legend {
			font-size: 12px;
			margin-bottom: 10px;

			.title {
				font-size: 16px;
				margin-right: 20px;
			}

			.color {
				display: inline-block;
				vertical-align: middle;
				margin: 0 5px 2px 0;
				background: #ccc;
				width: 30px;
				height: 14px;
				border-radius: 4px;

				& + * {
					margin-left: 20px;
				}

				&.red {
					background: #d44;
				}

				&.green {
					background: #4c0;
				}
			}
		}
	}

	.fires-area {
		.fires-area-chart {
			canvas {
				width: 100% !important;
				height: 100% !important;
			}
		}

		.legend {
			font-size: 12px;
			margin-bottom: 10px;

			.title {
				font-size: 16px;
				margin-right: 20px;
			}

			.color {
				display: inline-block;
				vertical-align: middle;
				margin: 0 5px 2px 0;
				background: #ccc;
				width: 30px;
				height: 14px;
				border-radius: 4px;

				& + * {
					margin-left: 20px;
				}

				&.red {
					background: #d44;
				}

				&.green {
					background: #4c0;
				}
			}
		}
	}

	.danger, .emergency-situation {
		.ok-icon {
			display: inline-block;
			vertical-align: middle;
			margin-right: 5px;
			font-size: 20px;
			color: #4c0;
		}
	}

	.daily {
		margin-top: 30px;
		font-family: Roboto, sans-serif;

		.daily-row {
			display: flex;
			margin-bottom: 5px;
			gap: 15px;
			align-items: center;

			&:last-child {
				margin-bottom: 0;
			}

			.title {
				flex-grow: 1;
				font-size: 14px;
				font-weight: 500;
			}

			.number-column {
				display: flex;
				align-items: center;
				flex-basis: 23%;
				flex-shrink: 0;
				border-radius: 5px;
				padding: 5px 10px;
				text-align: center;

				&.red {
					background: #fceeee;
				}

				&.green {
					background: #dffbdb;
				}

				&.blue {
					background: #f2f6fc;
				}

				label {
					color: var(--dark-grey);
					flex-shrink: 0;
				}

				.number-value {
					flex-grow: 1;
					text-align: right;
					font-size: 16px;
					font-weight: bold;
				}
			}
		}
	}

	@media (min-width: 768px) and (max-width: 1199px) {
		.daily {
			flex-wrap: wrap;
			gap: normal;
			justify-content: space-between;

			.block {
				flex-basis: calc(50% - 10px);
			}
		}
	}

	.cities {
		padding: 20px 0;
		border-bottom: 1px solid rgba(#000, 0.15);
		display: flex;
		align-items: flex-start;
		gap: 20px;

		&:last-child {
			border: 0;
		}

		.title-block {
			flex-basis: 300px;
			flex-grow: 0;
			flex-shrink: 0;
			background: #fceeee;
			border-radius: 4px;
			padding: 12px;

			&.underway {
				background: #dffbdb;
			}

			&.unattended {
				background: #fde9d8;
			}

			h3 {
				font-weight: normal;
				font-size: 14px;
				text-transform: uppercase;
			}

			strong {
				display: block;
				font-size: 20px;
				margin: 5px 0;
			}

			em {
				display: block;
				font-style: normal;
				color: var(--dark-grey);
			}
		}

		table {
			flex-grow: 1;

			th,
			td {
				vertical-align: top;

				&.center {
					text-align: center;
				}
			}

			th {
				text-align: left;
				font-weight: normal;
				color: var(--grey);
				font-size: 12px;
				line-height: 1.2em;
			}

			td {
			}
		}
	}
}
