.search-icon-input {
	display: flex;
	align-items: center;
	border: 1px solid transparent !important;
	border-radius: 5px;
	background-color: var(--bg) !important;
	padding: 0 10px;
	width: 505px;
	height: var(--ui-element-height);

	input {
		width: 100%;
		border: none;
		background: transparent;

		&:hover {
			background: transparent;
		}
	}

	&:hover {
		border-color: var(--primary) !important;
	}
}

.file-input {
	.file-attach {
		display: flex;
		flex-direction: column-reverse;
		gap: 5px;

		.accepted {
			color: var(--primary);
		}

		.uploaded-file {
			pointer-events: all;

			a {
				margin-right: 5px;
			}
		}
	}
}