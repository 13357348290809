.entity-single {
	.sp-row {
		align-items: center;

		.entity-button {
			div:nth-child(2) {
				padding-left: 20px;
			}
		}
	}

	.casualty-card {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		.casualty-card-info {
			width: 100%;

			.detecttime-birth {
				display: flex;
				flex-direction: row;
				gap: 24px;
				align-items: center;
			}
		}
	}
}
