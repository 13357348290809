.changed-popup {
    min-height: 140px;

    .sp-popup-content {
        .action {
            display: flex;
            gap: 20px;

            &>* {
                margin-left: 0;
            }
        }
    }
}