.waybill-single {
	.firedep-list {
		.firedep-card {
			.title {
				display: flex;
				gap: 20px;
				align-items: center;

				.name {
					flex-grow: 1;

					.reason {
						font-weight: normal;
						margin-left: 10px;
						font-size: 12px;
					}
				}

				.accepted {
					color: var(--primary);
					font-weight: normal;
					line-height: 24px;
					padding: 2px 10px;
					border-radius: var(--border-radius);
					background-color: var(--super-light-grey);
				}
			}

			.errors,
			.vehicle-no-consid-checkbox {
				padding: 10px;
			}

			.vehicles {
				padding: 10px 15px;

				.sp-table {
					td,
					thead th {
						padding: 5px;
					}
				}
			}

			&.card {
				margin-bottom: 24px;
			}
		}
	}

	.errors {
		& > div {
			padding: 10px;
			border-radius: 4px;
			background: #ffe0e0;
			border: 1px solid rgba(#000, 0.15);
			margin-bottom: 10px;
		}
	}
}

.fire-object-info {
	padding-bottom: 20px;
}
