.personnel-list {
    height: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;

    #table {
        flex-grow: 1;
    }

    .filters {
        display: flex;
        gap: 10px;
        margin-top: 10px;
    }
}