.temperature-tube-edit {
	width: 50%;

	.create-menu {
		display: flex;
		justify-content: center;
		padding: 5px 0;
		width: 100%;
		background: #f4f4f9;
	}
}
