.filterSelectsVehicle {
	display: flex;
	flex-wrap: wrap;
	margin-top: 1em;
}
.selectBox {
	margin-right: 1em;
	margin-top: 1em;
}

.registry-vehicle-filters {
	display: flex;
	gap: 20px;
	margin-top: 20px;
}
