.techfires-dashboard-items {
  --dashboard-gap: 20px;
  --legend-column-gap: 16px;
  --legend-row-gap: 8px;
  display: flex;
  flex-direction: row;
  gap: var(--dashboard-gap);

  .chart-count-fires {
    .card-content {
      display: flex;
      flex-direction: column;
      gap: var(--dashboard-gap);
    }
  }

  .left,
  .right {
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    gap: var(--dashboard-gap);
  }

  .legend {
    display: flex;
    column-gap: var(--legend-column-gap);
    row-gap: var(--legend-row-gap);
    flex-wrap: wrap;

    .item-legend {
      display: flex;
      gap: 5px;
      align-items: center;

      .color {
        width: 30px;
        height: 14px;
        border-radius: 3px;
      }
    }
  }

  .card-content {
    display: flex;
    flex-direction: column;
    gap: var(--dashboard-gap);

    .dashboard-table {
      width: 100%;

      .head {
        color: var(--grey);
        font-weight: 400;
        font-size: 14px;
      }

      tbody {
        .value {
          text-align: center;
          padding: 8px 0px;
          font-size: 14px;
          font-weight: 600;
        }

        td {
          padding: 8px 0px;
        }
      }
    }

    .filter-wrapper {
      display: flex;
      gap: var(--dashboard-gap);
      align-items: flex-start;

      .filter-item {
        display: flex;
        flex-direction: column;
        gap: 5px;
      }
    }
  }

  .equipment-by-fd {
    .card-content {
      max-height: 500px;
      overflow-y: auto;
    }

    .dashboard-table {
      th {
        text-align: left;
      }

      tbody {
        .value {
          text-align: left;
        }
      }
    }
  }

  .count-fires-table {
    table {
      .value {
        text-align: center;
      }
    }
  }


}