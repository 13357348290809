.techfire-card-main {
	display: flex;
	overflow: auto;

	.card-main-info {
		display: flex;
		flex-direction: column;
		gap: 24px;
		max-width: 1224px;
	}
}
