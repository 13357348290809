@use "drop-down";
@use "button";
@use "date-picker";
@use "scrollbars";
@use "form";
@use "table";
@use "tabs";
@use "input";
@use "checkbox";
@use "popup";
@use "pager";
@use "map";

.date-picker .react-datepicker-popper {
	z-index: 1500;
}

button.btn+button.btn {
	margin-left: 10px;
}

input,
textarea {
	border: 1px solid #ccc;
	border-radius: 4px;
	outline: none;

	&:hover {
		border-color: #999;
	}

	&:focus {
		border-color: #0af;
	}

	&::placeholder {
		color: #aaa;
	}
}

input {
	font-size: 14px;
	height: 2.1428571429em;
	padding: 0 5px;
}

textarea {
	padding: 5px;
}

input[type=radio] {
	padding: 0;
	margin: 0;
	height: auto;
	vertical-align: middle;
}

.tab-menu {
	--tab-active-text: #000;
	--tab-active-border-color: var(--primary);
	--tab-hover-text: var(--dark);
	--tab-hover-border-color: var(--light-grey);

	a {
		font-size: 12px;
		padding: 0 10px;
	}
}

.sp-portal {
	z-index: 9000;
}

.date-value {
	//font-family: sans-serif;
	font-variant-numeric: tabular-nums;
}

.sp-popover-content {
	font-family: Roboto, sans-serif;
	font-size: 12px;
	line-height: 1.3em;
}

.display-none {
	display: none;
}