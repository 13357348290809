.sp-btn {
	--default-border: transparent;
	--primary-bg: var(--primary);
	--default-text: var(--dark-grey);
	--primary-text: #fff;
	--default-font-size: --input-font-size;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

	height: calc(var(--ui-element-height) * 1em / --default-font-size);
	position: relative;

	&.dark {
		--primary-bg: var(--deep-dark);
		--primary-text: var(--lighter-grey);
		--disabled-bg: var(--lighter-dark);
		--disabled-text: var(--grey);
		--default-border: transparent;
		min-width: 2em;
	}

	&.btn-small {
		height: 25px !important;
		font-size: 12px !important;
		line-height: 12px !important;
	}
}