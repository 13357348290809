.techfire-actionbar {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	.title-save-cancel-button {
		display: flex;
		gap: 15px;
		align-items: center;
		text-align: center;

		.techfireid-createdAt {
			display: flex;
			align-items: center;
			text-align: center;
			gap: 15px;

			h1 {
				margin: 0;
			}
		}

		.custom-controls {
			margin: 0;
		}
	}
}
