.ui-input {
    &:disabled {
        &:hover {
            border-color: var(--input-border-color) !important;
        }
    }
}

.sp-suggestions-list {
    .suggestion-item {
        padding: 0 5px;
        height: var(--row-height);
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
        line-height: var(--row-height);
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        align-items: center;

        &:hover {
            color: var(--dropdown-hover-text);
            background: var(--dropdown-hover-bg);
        }
    }
}

.image-input {
        
}