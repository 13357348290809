.address-map-popup {
	.sp-popup-content {
		margin: -15px;
	}
}

.address-info {
	height: 100%;
	
	.hdr {
		font-size: 14px;
		height: 40px;
		line-height: 40px;
		padding: 0 10px;
		background: var(--light-accent);
		border-bottom: 1px solid rgba(#000, 0.2);
		display: flex;
		
		.name {
			display: flex;
			align-items: center;
			flex-grow: 0.9;
			font-weight: bold;

			.techfire-address-name {
				line-height: normal;
			}

			input {
				width: 100%;
			}

			.delete-address {
				display: flex;
				cursor: pointer;
				padding-left: 10px;
				gap: 5px;
				align-items: center;

				button {
					margin-left: 0;
				}

				svg {
					margin-right: 5px;

					* {
						fill: red;
					}
				}
			}

			svg {
				margin-right: 5px;
				
				* {
					fill: var(--primary);
				}
			}
		}
		
		.info {
			flex-grow: 0.1;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			
			svg {
				margin-top: -2px;
				margin-right: 3px;
				
				* {
					fill: #d00;
					stroke: #d00;
				}
			}
		}
	}


	.hdr.nearest-address {
		display: flex;
		align-items: center;
		line-height: normal;
		height: auto;
		min-height: 40px;
	}

	&:has(.nearest-address) {
		.address-objects {
			height: calc(100% - 80px);
		}
	}
	
	.address-objects {
		display: flex;
		align-items: flex-start;
		height: calc(100% - 40px);
		
		.sidebar {
			flex-basis: 200px;
			flex-shrink: 0;
			height: 100%;
			display: flex;
			flex-direction: column;
			background: var(--light-accent);
			border-right: 1px solid rgba(#000, 0.15);
			
			.sidebar-hdr {
				display: none;
				flex-basis: 40px;
				padding: 10px;
				border-bottom: 1px solid rgba(#000, 0.15);
				background: var(--light-grey);
			}
			
			.list {
				flex-grow: 1;
				overflow: auto;

				.object-btn {
					padding: 5px;
					cursor: pointer;
					display: flex;
					border-bottom: 1px solid rgba(#000, 0.1);
					
					.is-selected {
						flex-basis: 15px;
						flex-shrink: 0;
					}
					
					&:hover {
						background: var(--light-accent);
					}
					
					&.selected {
						background: var(--selected-bg);
						cursor: default;
						
						&:hover {
							background: var(--selected-bg);
						}
					}
					
					.red {
						color: #d00;
						margin-left: 10px;
					}
				}
				
				&.locked {
					pointer-events: none;
					
					.object-btn {
						&.selected {
							background: var(--secondary);
							color: #fff;
						}
					}
				}
				
				.empty {
					padding: 10px;
				}
			}
			
			.actions {
				flex-basis: 50px;
				flex-shrink: 0;
				padding: 10px;
			}
		}

		.content {
			flex-grow: 1;
			padding: 0 10px 10px 10px;
			height: 100%;
		}
		
		.address-object-card,
		.address-object-edit {
			height: 100%;

			.form-field {
				white-space: initial;
			}

			.ui-form,
			.form-fields {
				overflow: auto;
				height: 90%;
			}

			.form-fields-container {
				display: flex;
				align-items: flex-start;
				gap: 48px;
			}

			.object-controls {
				margin: 0 0 10px 0;
				height: 40px;
				display: flex;
				align-items: center;
				gap: 10px;
				border-bottom: 1px solid rgba(#000, 0.15);

				.sp-btn {
					height: 2.18181818em !important;
					line-height: calc(2.18181818em - 2px) !important;
					margin: 0;
				}
				
				.right {
					flex-grow: 1;
					display: flex;
					gap: 5px;
					justify-content: flex-end;
					align-items: center;
				}
			}

			.address-object-edit-container {
				display: flex;
				align-items: flex-start;
				gap: 48px;

				.address-object-fields {
					max-width: 400px;

					.address-object-fileinput {
						.file-attach {
							button {
								max-width: 185px;
							}
						}
					}
					.overnight-stay-checkbox,
					.manual-fill-checkbox {
						display: flex;
						flex-direction: row-reverse;
						justify-content: flex-end;
						gap: 10px;
					}
				}
			}
		}
	}
}

