.profile-page {
	height: 100%;
	.sp-col {
		max-width: 300px !important;
	}
	h3 {
		margin-bottom: 10px !important;
	}
	.email {
		&-label {
			position: relative;
			.email-exist {
				margin-left: 15px;
				position: absolute;
				color: var(--error);
				font-weight: bold;
				font-size: 14px;
			}
		}
	}

	.password {
		margin-bottom: 10px;
		position: relative;
		
		&-hint {
			color: var(--primary) !important;
		}

		svg {
			cursor: pointer;
			position: absolute;
			top: 10px;
			right: 10px;
			font-size: 16px;
			color: var(--primary);
		}
	}
	.role-select {
		max-width: 630px !important;
		width: 100%;
		height: auto;
		padding: 5px 0;

		.filter .filter-content {
			.show-value {
				padding: 0 !important;
				word-wrap: break-word;
				white-space: normal;

				.empty-item-tag {
					padding-left: 10px;
				}
			}
		}
	}

	.techfire-org {
		max-width: initial;
		input {
			width: 960px;
		}
	}
}
.org-tree {
	background-color: white;

	.title {
		cursor: pointer;
		line-height: 30px;
		padding: 0 10px;
		font-weight: 500;
		color: var(--dark);

		&.hide-org-tree {
			.icon-title {
				svg {
					transform: rotate(-180deg);
				}
			}
		}

		&.selected {
			background: var(--primary);
			color: var(--white);
		}

		.icon-title {
			display: inline-flex;
			align-items: center;
			gap: 12px;

			svg {
				*[fill] {
					fill: var(--primary);
				}
			}
		}

		.count {
			margin-left: 5px;
			color: #aaa;
			font-weight: normal;
		}
	}

	.pad {
		display: inline-block;
		width: 20px;
	}

	.hide-org-tree + .org-tree {
		display: none;
	}
}
