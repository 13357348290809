.sp-table {
    &.infinity-scroll,
    &.skeleton-row {
        tbody {
            tr:nth-child(2n), {
                background: #f8f8f8;
            }
        }

        tfoot {
            position: relative;
            background-color: #fff;

            tr {
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);

            }

            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 100%;
                height: 100%;
                background-color: grey;

                animation: fadeInOut 3s infinite;
            }

            .skeleton-row {
                .string-value,
                .number-value,
                .date-value {
                    color: transparent;
                }
            }
        }
    }
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.4;
    }
    100% {
        opacity: 0;
    }
}
