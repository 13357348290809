.forest-fire-movement-edit {
    display: flex;
    gap: 20px;
    width: 100%;

    .fire-movement-form {
        flex-basis: 1200px;
    }

    .fire-card-wrapper {
        flex-grow: 1;
    }
}