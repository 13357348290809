.labels-edit {
	min-height: 30px;

	.suggest-input {
		input {
			height: 30px !important;
		}
	}

	.label-item {
		display: inline-block;
		vertical-align: top;
		margin-right: 3px;
		background: #ddd;
		height: 30px;
		line-height: 30px;
		padding: 0 15px;
		border-radius: 15px;

		.remove {
			margin-left: 10px;
			cursor: pointer;

			&:hover {
				color: #d00;
			}
		}

		&.existing-in {
			background: #f88;
			transition: none;
		}
	}
}

.box-label-full {
	display: inline-block;
	vertical-align: middle;
	padding: 0 12px;
	line-height: 22px;
	margin-right: 8px;
	margin-bottom: 5px;
	border-radius: 5px;
	font-size: 12px;

	.close-btn {
		display: inline-block;
		vertical-align: middle;
		margin-left: 5px;
		width: 18px;
		height: 18px;
		cursor: pointer;
		transition: all var(--transition-timing);

		&:hover {
			transform: scale(1.1, 1.1);
			filter: brightness(1.2) drop-shadow(1px 1px 0 rgba(0, 0, 0, 0.1));
		}
	}
}
