.firedep-list-container {


	.tree-root {
		margin-top: 40px;
		font-size: 14px;

		.org-tree {
			background-color: white;

			.title {
				cursor: pointer;
				line-height: 30px;
				padding: 0 10px;
				font-weight: 500;
				color: var(--dark);

				&.hide-org-tree {
					.icon-title {
						svg {
							transform: rotate(-180deg);
						}
					}
				}

				&.selected {
					background: var(--black);
					color: var(--white);
				}

				.icon-title {
					display: inline-flex;
					align-items: center;
					gap: 12px;

					svg {
						*[fill] {
							fill: var(--primary);
						}
					}
				}

				.count {
					margin-left: 5px;
					color: #aaa;
					font-weight: normal;
				}
			}

			.pad {
				display: inline-block;
				width: 20px;
			}

			.hide-org-tree+.org-tree {
				display: none;
			}
		}
	}

	.firedep-list {
		display: flex;
		gap: 30px;
		margin-top: 15px;
		height: calc(100% - 110px);

		.sidebar {
			flex-basis: 260px;
			flex-shrink: 0;
		}

		.content {
			flex-grow: 1;

			#table {
				height: 100%
			}

			.ui-pager {
				margin: 0 0 15px 0;
			}
		}
	}
}