.show-value-placeholder {
    color: var(--grey);
}

.city-with-county {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .county {
        font-weight: bold;
        font-style: italic;
    }
}