.techfire-address-select {
	width: 100%;
	
	.techfire-address-value {
		width: 100%;
		margin-left: 10px;
		display: flex;
		align-items: center;
		
		.object-section {
			flex-grow: 1;
			display: flex;
			gap: 5px;
			align-items: center;

			.rank {
				background: #ddd;
				height: 20px;
				line-height: 20px;
				font-size: 11px;
				padding: 0 5px;
				border-radius: 4px;
				max-width: max-content;
			}
			
			.no-value {
				background: #d00;
				color: #fff;
				height: 20px;
				line-height: 20px;
				font-size: 11px;
				padding: 0 5px;
				border-radius: 4px;
				max-width: max-content;
			}
		}

		.other-address-btn {
			background: #0af;
			color: #fff;
			height: 20px;
			line-height: 20px;
			font-size: 11px;
			padding: 0 5px;
			border-radius: 4px;
			max-width: max-content;
		}
	}
}

.techfire-address-popup {
	background: #fff;
	width: 600px;
	height: 400px;
	border: 1px solid rgba(#000, 0.15);
}
