.personnel-filters {
	display: flex;
	align-items: center;
	gap: 20px;
	margin-bottom: 20px;

	&>* {
		flex-basis: 200px;
		flex-shrink: 1;
	}
}

.personnel-basic {
	margin-bottom: 1em;
	margin-top: 4px;
}

.personnel-vehicle {
	margin-bottom: 1em;
}