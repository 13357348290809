.sp-popup {
    .sp-popup-window {
        padding: var(--module-page-padding);
        background-color: var(--super-light-grey);
        max-height: 100%;
        overflow: auto;

        .sp-popup-close-btn {
            display: none;
        }

        .form-submit {
            padding-bottom: 0;
        }
    }
}