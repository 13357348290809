.address-map-popup {
	.sp-popup-content {
		margin: -15px;
	}
}

.address-info {
	height: 100%;

	.hdr {
		font-size: 14px;
		height: 40px;
		line-height: 40px;
		padding: 0 10px;
		background: var(--light-accent);
		border-bottom: 1px solid rgba(#000, 0.2);
		display: flex;

		.name {
			display: flex;
			align-items: center;
			flex-grow: 0.9;
			font-weight: bold;

			.permafrost-address-name {
				line-height: normal;
			}

			input {
				width: 100%;
			}

			.delete-address {
				display: flex;
				cursor: pointer;
				padding-left: 10px;
				gap: 5px;
				align-items: center;

				button {
					margin-left: 0;
				}

				svg {
					margin-right: 5px;

					* {
						fill: red;
					}
				}
			}

			svg {
				margin-right: 5px;

				* {
					fill: var(--primary);
				}
			}
		}


	}
}

