.map-disabled{
	display: none;
}

.fires-map {
	background: #d0c8c0 !important;
	cursor: grab;

	&.heatpoint-hover {
		cursor: pointer;
	}

	.leaflet-popup-content {
		.loading {
			text-align: center;
		}
	}

	.sc-panel {
		transform: translate(-90px, 0);
	}
}

.fires-map, .fire-card-map {

	.map-popup-closer {
		color: var(--dark-grey);
	}

	.main-popup {
		min-height: 200px;
		color: var(--dark-grey);

		.fires-popup-tabs {
			--tab-text: #888;
			--tab-bg: transparent;
			--tab-parent-rgb: 255, 255, 255;
			--tab-border-width: 2px;
			--tab-border-color: transparent;
			--tab-hover-text: #000;
			--tab-hover-border-color: #ccc;
			--tab-active-text: var(--primary);
			--tab-active-border-color: var(--primary);
			--tab-active-bg: transparent;

			.sp-tabs-container {
				margin-bottom: 15px;
				height: 31px;
				width: 100%;
				overflow: hidden;
				padding-left: 1px;

				.sp-tab {
					height: 30px;
					line-height: 30px;

					.tab {
						display: flex;
						align-items: center;
						font-weight: bold;

						svg, img {
							height: 20px;
							margin-right: 5px;

							path {
								fill: var(--dark-grey);
							}
						}
					}
				}
				.active {
					svg {
						path {
							fill: var(--primary) !important;
						}
					}
				}
			}
			.sp-tabs-content {
				height: 100%;
			}
		}
	}
}

.fires-popup-content {
	padding-left: 1px;
	position: relative;
	line-height: 1.4em;

	.tab-content {
		display: none;
		// position: absolute;
		// left: 0;
		// top: 0;
		// width: 100%;
		// height: 100%;

		&.active {
			display: block;
		}
	}

	.fire,
	.airfield,
	.aviaDep,
	.isdm,
	.forestry-quarter {
		display: flex;
		flex-direction: column;
		.title {
			display: flex;
			justify-content: space-between;
			margin-bottom: 10px;
			.id {
				cursor: pointer;
				color: var(--primary);
			}
		}

		.main-info {
			.underline {
				border-bottom: 1px solid var(--light-grey);
			}
		}
		.show-more {
			:hover {
				color: var(--primary);
			}
			cursor: pointer;
			color: var(--grey);
			svg {
				width: 100%;
				margin: 0 auto;
				font-size: 25px;
			}
		}
	}
}

.weather-popup {
	display: flex;
	align-items: flex-start;

	.weather-icon {
		text-align: center;
		margin-left: 10px;
		margin-top: -10px;
		flex-basis: 100px;

		img {
			display: inline-block;
		}
	}

	.wind-dir {
		display: inline-block;
		vertical-align: middle;
		margin: 0 5px;
		width: 16px;
		height: 16px;
		line-height: 16px;
		border-radius: 50%;
		text-align: center;
		font-size: 14px;
		background: #e0e8ff;
		color: #333;
	}
}

.map-scale-container {
	//transform-origin: right bottom;
	//transform: scale(3);
	position: absolute;
	bottom: 10px;
	right: 10px;
	height: 20px;
	border-radius: 10px;
	width: 302px;
	background: #fff;
	border: 1px solid rgba(#fff, 0.5);
	z-index: 100;
	color: #fff;
	font-size: 12px;
	text-shadow: 1px 2px rgba(#000, 0.9);

	&.hidden {
		display: none;
	}

	&.in-card {
		bottom: 80px;
	}

	.map-scale {
		width: 100%;
		height: 100%;
		position: relative;

		canvas {
			width: 100%;
			height: 100%;
			border-radius: 9px;
		}

		.min-value {
			position: absolute;
			left: 10px;
			top: 0;
		}

		.max-value {
			position: absolute;
			right: 10px;
			top: 0;
		}

		.measure {
			position: absolute;
			left: 50%;
			transform: translate(-50%, 0);
			top: 0;
		}
	}
}

.fires-extra-layers {
	.filters-layout:not(:last-child) {
		padding-bottom: 5px;
		margin-bottom: 8px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	}
}