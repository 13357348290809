.emergency-module,
.techfires-module,
.fires-module,
.covid-module,
.permafrost-module,
.delivery-module {
	background-color: var(--super-light-grey);
	height: 100%;
	overflow: auto;
}

body {
	--module-page-padding: 20px;
}

.audit-page,
.administration-page,
.emergency-page,
.techfires-page,
.fires-page,
.profile-page,
.permafrost-page,
.delivery-page {
	padding: var(--module-page-padding);
	height: 100%;
	min-height: 100%;

	&:empty {
		display: none;
	}
}

.audit-page,
.administration-page,
.profile-page,
.fires-page,
.emergency-page,
.techfires-page,
.permafrost-page,
.delivery-page,
.fires-popup,
.dashboard-public,
.sp-map,
.map-dynamic-layers,
.sp-popup {
	// здесь только стили внутренних элементов, прямые стили не задавать, страница и попап - разные вещи

	// datepicker
	.sp-date-picker {
		height: var(--ui-element-height);
		min-width: 200px;

		input {
			padding: 12px;
		}

		.clear-btn {
			right: 10px;
		}

		&::before {
			content: "";
			position: absolute;
			width: 18px;
			height: 18px;
			top: 9px;
			right: 11px;
			background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='18' height='18' viewBox='0 0 19 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M15.4 2.80005H2.8C1.80589 2.80005 1 3.60594 1 4.60005V17.2C1 18.1942 1.80589 19 2.8 19H15.4C16.3941 19 17.2 18.1942 17.2 17.2V4.60005C17.2 3.60594 16.3941 2.80005 15.4 2.80005Z' stroke='%23B8B8B8' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3cpath d='M12.7 1V4.6' stroke='%23B8B8B8' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3cpath d='M5.5 1V4.6' stroke='%23B8B8B8' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3cpath d='M1 8.19995H17.2' stroke='%23B8B8B8' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e ");
		}
	}

	// select
	.sp-dropdown {
		min-width: 100px;
		min-height: var(--ui-element-height) !important;
		width: 100%;
		align-items: center !important;
		vertical-align: middle;
		position: relative;
		display: flex;
		align-items: center;

		.filter-arrow {
			display: none;
		}

		.sp-dropdown-item {
			height: 30px;
		}

		.filter {
			height: 100%;
			width: calc(100% - 30px);

			&::before {
				transition: transform 250ms;
				position: absolute;
				content: "";
				right: 12px;
				top: 15px;
				width: 10px;
				z-index: 1;
				height: 6px;
				background-repeat: no-repeat;
				background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M9 1L5 5L1 1' stroke='%23B8B8B8' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e ");
			}

			.show-value {
				font-size: 14px;
				height: 100%;
				padding: 0 5px;
				align-items: center;
				display: block;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;

				.item-tag {
					display: inline-block;
					vertical-align: top;
					background: #ddd;
					color: #333;
					font-weight: normal;
					max-width: calc(100% - 24px);
					margin: 1px;
					border-radius: 2px;
					padding: 0 7px;
					height: 26px;
					border: 1px solid #ccc;
					line-height: 24px;
					user-select: none;
					white-space: nowrap;

					.item-tag-content {
						display: inline-block;
						vertical-align: middle;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						font-size: 12px;
					}

					svg {
						vertical-align: middle;
					}

					.remove-btn {
						cursor: pointer;
						margin-left: 10px;
					}
				}
			}
		}

		&.active {
			.filter {
				&::before {
					transform: scale(1, -1);
				}
			}
		}
	}

	// input
	.ui-input,
	.string-value,
	.number-value,
	.object-value,
	.boolean-value,
	.date-value,
	.color-value,
	input {
		height: var(--ui-element-height) !important;
		line-height: var(--ui-element-height);
		font-size: var(--input-font-size);
	}

	.ui-input,
	input {

		&:not(.disabled):hover,
		&:not(.disabled):focus {
			border-color: var(--primary);
		}

		padding: 0 10px;
	}

	// table
	.sp-table {
		padding: 24px !important;
		overflow: hidden;

		thead tr {
			height: 40px;
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);

			th {
				font-size: 14px;
				line-height: 16px;
				background: var(--white);
				vertical-align: middle;
				font-weight: bold;

				.column-header {
					position: relative;

					.column-title {
						overflow: inherit;

						a {
							color: var(--black);

							&:hover {
								color: var(--primary);
							}
						}
					}

					.field-sort {
						//position: absolute;
						//left: -18px;
						color: transparent;

						svg {
							width: 16px;
							height: 16px;

							&.fa-chevron-down {
								background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQBAMAAADt3eJSAAAAMFBMVEUAAACAgIC5ubm5ubm5ubm3t7e4uLi4uLi4uLi3t7e4uLi3t7e4uLi4uLi4uLj///9CphPcAAAAD3RSTlMAAgsWM0NES1Nuc5nZ7e6Bagj4AAAAAWJLR0QPGLoA2QAAAC1JREFUCNdjYMANWNLAwIFB5h0YHMSmiHM3EDQgM7AA9nvvFoAZTGFpBgz4AAAyFxNlsUl8nQAAAABJRU5ErkJggg==");
							}

							&.fa-chevron-up {
								background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQBAMAAADt3eJSAAAAMFBMVEUAAACAgIC5ubm5ubm5ubm3t7e4uLi4uLi4uLi3t7e4uLi3t7e4uLi4uLi4uLj///9CphPcAAAAD3RSTlMAAgsWM0NES1Nuc5nZ7e6Bagj4AAAAAWJLR0QPGLoA2QAAAC1JREFUCNdjYMANWNLAwIFB5h0YHMSmiHM3EDQgM7AA9nvvFoAZTGFpBgz4AAAyFxNlsUl8nQAAAABJRU5ErkJggg==");
								transform: rotate(180deg);
							}
						}
					}
				}
			}
		}

		tbody tr {
			background: var(--white);

			&:not(:first-child) {
				height: 40px !important;
				font-size: 14px;
				line-height: 16px;
			}

			.color-value {
				height: 24px !important;
				width: 24px !important;
			}
		}

		th,
		td {
			font-size: 14px;
			line-height: 16px;
			padding: 0 15px;
		}

		.number-value {
			color: inherit;
		}
	}

	.form-field {
		flex: 1;
		width: 100%;

		.sp-dropdown,
		.sp-date-picker {
			width: 100% !important;
		}

		label {
			margin-bottom: 8px;
			color: var(--dark-grey);;
		}
	}

	//tabs
	.sp-tabs {
		.sp-tabs-container {
			margin-bottom: 20px !important;
			border-bottom: 3px solid var(--light-grey) !important;

			.sp-tab {
				color: var(--dark-grey);
				font-weight: 700;
				padding: 0;
				margin-right: 30px;

				&.active {
					color: var(--black);
				}
			}
		}
	}

	// button
	.sp-btn {
		height: var(--element-height);
		font-size: 14px;
	}

	.tox-tinymce {
		.tox-toolbar__primary {
			background-color: var(--light-grey) !important;
			height: 40px;
		}

		.tox-statusbar {
			border-top: none !important;

			&__text-container {
				display: none;
			}
		}

		margin-bottom: 20px;
	}

	.ui-pager {

		//font-size: 16px;
		a {
			&:hover {
				color: var(--white) !important;
			}
		}
	}

	textarea {
		max-width: 100%;
		min-width: 100%;
		min-height: 70px;
		max-height: 200px;

		&:hover {
			border-color: var(--primary);
		}
	}
}

.permafrost-module:has(.permafrost-map) {
	overflow: hidden;
}