.ship-popup {
    width: 430px;
    max-width: 430px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .image {
        height: 200px;
        width: 100%;
    }

    .no-image {
        height: 50px;
        width: 100%;
    }

    .image {
        object-fit: contain;
    }

    .no-image {
        border: 2px dashed var(--light-grey);
        display: grid;
        place-items: center;
    }

    .ship-tab-content {
        height: 180px;
        overflow: scroll;
    }

    .controls {
        margin-top: 10px;


        button {
            width: 100%;
        }
    }
}