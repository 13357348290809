.city-edit-page {
    display: flex;
    height: 100%;
    gap: 20px;

    .left {
        width: 50%;
        height: 100%;

        .resources {
            button {
                margin-bottom: 10px;
            }
        }
    }

    .right {
        .city-map-card {
            margin-top: 30px;

            .full-map {
                height: 600px;
            }
        }



        flex-grow: 1;
    }
}

.reserves-list {
    .reserves-filters {
        padding-top: 20px;
    }
}

.reserve-popup {
    padding: var(--module-page-padding);
    background-color: var(--super-light-grey);
    max-height: 100%;

    .unit-input {
        width: 100%;
    }

    .sp-popup-close-btn {
        display: none;
    }

    &.request-changed-popup,
    &.copy-popup {
        min-height: 150px !important;

        .sp-popup-content {
            display: flex;
            justify-content: space-between;
            flex-direction: column;

            .action {
                margin-top: 20px;
                display: flex;
                gap: 20px;

                button {
                    margin-left: 0;
                }
            }
        }
    }
}