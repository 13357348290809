.numbers-row {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  gap: 15px;

  .number-block:last-of-type {
    margin-left: 50px;
  }

  .number-block {
    padding: 12px;
    background: var(--super-light-grey);
    border-radius: 5px;
    min-height: 160px;

    &.green {
      background: rgba(223, 251, 219, 0.4);
    }

    &.red {
      background: rgba(255, 216, 229, 0.4);
    }

    .number-container {
      display: flex;
      justify-content: space-between;
      height: 110px;
    }

    .number-container-right {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }

    .number-appg {
      color: rgb(73, 78, 88);
      font-family: Roboto;
      font-size: 18px;
      font-weight: 500;
      text-align: right;
    }

    .block-label {
      display: block;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      color: var(--dark-grey);
      margin-bottom: 15px;
      text-transform: uppercase;

      &.green {
        color: rgb(66, 147, 44);
      }
  
      &.red {
        color: rgb(205, 65, 65);
      }
    }

    .number-value {
      color: rgb(46, 50, 56);
      font-family: Roboto;
      font-size: 48px;
      font-weight: 700;
    }
    .number-percent {
      display: block;
      text-align: end;
      margin-top: 15px;
      font-family: Roboto;
      font-size: 20px;
      font-weight: 400;

      &.green {
        color: #01A204;
      }
  
      &.red {
        color: #FF0D0D;
      }
    }
  }
}
