.techfire-sidebar {
	max-width: 360px;
	background-color: var(--white);
	overflow-y: auto;

	.sidebar-item {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 12px 16px;
		align-items: center;
		overflow-wrap: break-word;
		white-space: normal;
		cursor: pointer;

		color: var(--dark);
		font-size: 14px;
		font-weight: 500;
		line-height: 16px;
		text-align: left;

		&:hover {
			background-color: var(--dark);
			color: var(--light-grey);
		}

		&.active {
			background-color: var(--dark);
			color: var(--light-grey);
		}

		.check-icon {
			width: 24px !important;
			height: 24px !important;
		}
	}
}
