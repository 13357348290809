.sp-dropdown {
	--dropdown-selected-bg: var(--selected-bg);
	--dropdown-selected-text: var(--text);

	&.disabled {
		filter: none !important;
	}

	&.dark {
		--dropdown-hover-bg: var(--lighter-dark);
		--dropdown-hover-text: var(--lighter-grey);
		--dropdown-selected-bg: var(--primary);
		--dropdown-selected-text: var(--lighter-grey);
		--dropdown-border: var(--deep-dark);
		--dropdown-bg: var(--dark);
		--dropdown-text: var(--lighter-grey);
		--dropdown-trigger-bg: var(--deep-dark);
		--dropdown-trigger-text: var(--lighter-grey);

		--select-actions-bg: var(--deep-dark);
		--select-actions-text: var(--lighter-grey);

		//--pager-font-size: 14px;
		--pager-link: var(--primary);
		--pager-text: var(--grey);
		--pager-hover-bg: var(--lighter-dark);
		--pager-hover-text: #fff;
		--pager-active-text: #fff;
		--pager-disabled-text: #888;
		--pager-border-color: rgba(#000, 0.15);
		--pager-go-bg: none;
		--pager-go-text: var(--grey);
		--pager-go-hover: var(--primary);
		--pager-direct-page-text: var(--light-grey);
		--pager-direct-page-bg: var(--deep-dark);

		&.sp-dropdown-trigger {
			.filter {
				.filter-content {
					padding: 0 15px;
				}

				.filter-arrow {
					padding-right: 15px;
				}

				input::selection {
					background: var(--primary);
				}
			}
		}

		&.sp-dropdown-list {
			.sp-dropdown-items {
				.sp-dropdown-item {
					padding: 0 15px;
				}
			}
		}

		.actions {
			height: 42px;
			margin: 0;
			padding: 0 5px;
			display: flex;
			align-items: center;
			border: 0;
		}

		.ui-pager {
			margin-left: 10px;
		}
	}
}

.record-multi-select {
	.filter-content {
		padding: 5px !important;
	}
	.sp-dropdown-items {
		padding-left: 5px;
	}
	.sp-dropdown-item {
		&.multiple {
			.checkbox {
				display: none;
			}
		}
	}
}
