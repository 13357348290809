.techfire-shifts {
	max-width: 1200px;

	.firedepshift-form {
		.form-submit {
			padding: 0;
		}
	}
	
	.title {
		margin-bottom: 15px;
		
		h1 {
			margin: 0;
		}
		
		display: flex;
		align-items: center;
		gap: 10px;
		
		.main-dispatch {
			font-size: 12px;
			font-weight: normal;
			background: #fff;
			border: 1px solid rgba(#000, 0.15);
			padding: 0 10px;
			line-height: 20px;
			border-radius: 4px;
			display: flex;
			align-items: center;
			gap: 10px;
			
			&:before {
				content: '';
				display: block;
				width: 10px;
				height: 10px;
				border-radius: 50%;
				background: var(--primary);
			}
		}
	}
	
	.list{
		display: flex;
		flex-direction: column;
		gap: 15px;
	}
	
	.firedep-list {
		.on-shift {
			color: var(--success);
		}
		
		.not-on-shift {
			color: var(--danger);
			font-size: 11px;
		}

		.sp-table {
			tr.disabled {
				td {
					pointer-events: none;
					opacity: 0.7;
				}

				.not-on-shift {
					color: var(--grey);
				}
			}
		}
	}
}