.dictionaries-header {
    display: flex;
    justify-content: flex-end;

    .dictionaries-header-button {
        position: absolute;
    }
}

.dictionaries-edit {
    min-height: 200px;

    .dictionaries-new-group {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .dictionaries-edit-body {
        max-width: 500px;

        .dictionaries-edit-body-toolbar {
            display: flex;
            gap: 20px;
        }

        .dictionaries-edit-body-items {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 20px 0 30px;

            .dictionaries-edit-body-wrapper {
                display: flex;
                flex-direction: column;
                gap: 10px;

                h3 {
                    margin-bottom: 10px;
                    margin-top: 5px;
                }
            }

            .dictionaries-edit-body-item {
                background: var(--white);
                display: flex;
                justify-content: space-between;
                width: 100%;
                align-items: center;
                text-decoration: none;
                color: var(--black);
                padding: 12px 24px;
                font-size: 14px;
                cursor: pointer;

                .dictionaries-edit-body-filter {
                    display: flex;
                    justify-content: space-between;

                    .order-btn {
                        cursor: pointer;
                        margin-right: 5px;

                        &-disabled {
                            pointer-events: none;
                            color: #ccc;
                        }
                    }
                }

                .priority {
                    font-size: 11px;
                    color: var(--secondary);
                }
            }
        }
    }
}

.dictionaries-popup {
    .dictionaries-popup-wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .drop-down {
            width: 100%;
        }
        .popup-number {
            width: 100%;
        }
    }
}