.label-field {
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
    label {
        font-size: 12px;
        line-height: 14px;
        color: var(--dark-grey) !important;
        position: relative;
    }
    span.text {
        font-size: 14px;
        line-height: 16px;
        font-weight: 500;
    }
}
