.fire-card-page {
    .field-empty {
        border-color: red;
    }

    .location {
        margin-top: 10px;
    }

    .card-form {
        display: flex;
        gap: var(--fire-card-gap);
        height: calc(100vh - 250px);
        overflow-y: auto;

        .left,
        .right {
            display: flex;
            gap: var(--fire-card-gap);
            flex-direction: column;
            width: 100%;
        }

        .left {
            max-width: var(--big-card-width);
        }

        .right {
            max-width: var(--small-card-width);
        }
    }

    .forestry-table {
        width: 100%;
        border-spacing: 0 1px;
        border: 1px solid var(--light-grey);
        border-radius: 4px;

        th,
        td {
            height: var(--row-height);
        }

        th {
            background: var(--super-light-grey);

            &:first-child {
                text-align: left;
            }
        }

        td,
        th {
            padding: 3px 10px;
            border-bottom: 1px solid var(--super-light-grey) !important;

            input {
                min-width: 80px;
                width: 100%;
            }
        }

        tr {
            &:last-child {
                td {
                    border: unset !important;
                }
            }
        }
    }
}