.modes {
	display: flex;
	column-gap: 10px;
	svg {
		background-color: #fff;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
		border-radius: 5px;
		border-color: unset !important;
		cursor: pointer;
		transition: all 200ms ease-out;
		opacity: 0.5;
		width: var(--ui-element-height);
		height: var(--ui-element-height);
		border: 2px solid transparent !important;
		.icon-border {
			stroke: unset;
		}
		.icon-content {
			stroke: rgba(#323232, 0.6);
		}
		&:hover {
			opacity: 0.7;
			transform: scale(1.1, 1.1);
			.icon-content {
				stroke: var(--primary);
			}
		}
		&.active {
			pointer-events: none;
			transform: scale(1.1, 1.1);
			opacity: 1;
			border-color: var(--primary) !important;
			.icon-content {
				stroke: var(--primary);
			}
		}
	}
}
