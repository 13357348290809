.fire-card-page {
    .fire-sidebar {
        box-shadow: 1px 0 5px rgba(#000, 0.15);
        display: flex;
        flex-direction: column;
        min-width: 230px;
        padding: 16px;
        background-color: var(--white);
        height: 100%;

        .title {
            font-size: 20px;
            line-height: 24px;
            display: flex;
            flex-direction: column;
            margin-bottom: 24px;
            padding-top: 10px;
            .id {
                color: var(--primary);
            }
            .county {
                font-weight: 700;
                height: 24px;
            }
        }

        .list {
            margin-right: -16px;
            overflow-y: auto;
            margin-bottom: 5px;
            .item {
                display: flex;
                height: 32px;
                cursor: pointer;
                border-left: 2.5px solid grey;
                display: flex;
                align-items: center;
                padding: 10px 12px;
                margin-bottom: 2px;
                width: 200px;

                .date {
                    margin-left: 3px;
                    display: flex;
                    font-weight: 500;
                    & > * {
                        margin-right: 10px;
                    }
                }

                &.active {
                    color: var(--primary);
                }

                svg {
                    margin-left: 40px;
                }
            }
        }
    }
}

.changed-fire-warning-popup {
    height: 150px;
    .sp-popup-close-btn {
        display: none;
    }
    .sp-popup-content {
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}
