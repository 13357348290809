.firedep {
	height: 100%;
	display: flex;
	flex-direction: column;

	.geo-edit {
		width: 960px;

		.edit-area {
			width: 960px;
			height: 600px;
		}
	}

	.sp-tabs {
		flex-grow: 1;

		.sp-tabs-content {
			height: 100%;
		}
	}


}