.wrapper-setting-note{
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 400px;

  .separator{
    width: 100%;
    height: 2px;
    background: var(--light-grey);
  }

  .wrapper-array-items{
    display: flex;
    flex-direction: column;
    gap: 10px;

    .wrapper-array-item{
      display: flex;
      gap: 10px;
      align-items: center;

      p{
        flex-basis: 50%;
      }
    }
  }
}