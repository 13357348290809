.address-layer-filter {
	.settings {
		display: flex;
		flex-direction: column;
		row-gap: 5px;
	}
	h4 {
		margin-bottom: 2px;
	}
}

.map-fire-address-popup {
	.overnight-stay-people {
		padding-bottom: 16px;
		color: var(--primary);

		span {
			background-color: var(--primary);
			width: 10px;
			height: 10px;
			display: inline-block;
			margin-right: 5px;
			border-radius: 50%;
		}
	}
}
