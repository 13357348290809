.add-row {
	height: 40px;
	padding: 6px 0;
	background: var(--super-light-grey);
	text-align: center;

	.sp-dropdown-items {
		.active {
			background-color: var(--primary);
		}
	}

	.add-btn {
		cursor: pointer;
		border-radius: 50%;
		box-shadow: 0 1px 2px rgba(#000, 0.05);
		transition: all 150ms;

		&:hover {
			transform: scale(1.1, 1.1);
			box-shadow: 0 2px 3px rgba(#000, 0.15);
		}
	}

	&.disabled {
		pointer-events: none;
		filter: saturate(0);
	}
}