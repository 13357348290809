.composite-toolbar {
	display: flex;
	align-items: center;
	column-gap: 10px;
	pointer-events: all;

	.sp-btn:hover {
		box-shadow: inherit;
	}

	& > * {
		height: var(--ui-element-height) !important;
	}
}
