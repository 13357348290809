.reserve {

  .main-info {

    .normal input {
      border-color: var(--success);
      //background: var(--success);
    }
    .danger input {
      border-color: var(--danger);
      //background: var(--danger);
    }
  }
}
