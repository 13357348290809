.ui-pager {
    a {
        color: var(--dark-grey) !important;

        &:hover {
            color: #fff !important;
        }

        &.active {
            font-weight: 600;
            color: var(--black) !important;
        }

        &.disabled {
            color: var(--grey) !important;
        }
    }

    &-total {
        color: var(--dark-grey) !important;
    }
}