.fire-card-page {
    .fire-card-map-container {
        height: 400px;
        &.map-maximized {
            flex-grow: 1;
        }
    }

    .fire-card-map {
        background: var(--grey);

        &.is-picking {
            cursor: crosshair;
        }

        .map-scale-container {
            right: 50%;
            margin-right: -150px;
        }
    }

    .fires-timeline {
        left: 10px;
        right: 10px;
        .sp-date-picker {
            display: flex;
            align-items: center;
            &:before {
                display: none;
            }
        }
    }

    .preview {
        .fires-filters-minimized,
        .map-right-sidebar,
        .fires-timeline,
        .sp-panel,
        .minimize-btn,
        .sp-slide-input {
            display: none;
        }
        .map-additional-controls {
            left: 10px;
            top: 10px;
        }

        .sc-panel-wrapper,
        .minimize-btn {
            width: 0 !important;
            .map-additional-controls {
                left: 0px;
                top: -15px;
            }
        }
        .scale-line-container {
            bottom: 15px;
        }
    }
}
