.fire-registry {
	height: 100%;
	display: flex;
	flex-direction: column;
	padding-bottom: 10px !important;
	position: relative;
	.top {
		// position: sticky;
		// top: 20px;

		.filters {
			margin-bottom: 20px;

			display: flex;
			flex-direction: column;
			gap: 10px;
			&-bottom {
				display: flex;
				flex-wrap: wrap;
				gap: 10px;
			}
		}
	}

	.bottom {
		overflow: auto;
	}

	.color-column {
		width: 5px !important;
		padding: 0 !important;
	}
	td.color-column {
		padding: 0 !important;

		.string-value {
			height: 40px !important;
			.status-color {
				display: flex;
				margin-left: 0;
				height: 100%;
				border-left: #000 solid 5px;
				border-radius: unset;
				background: unset;
			}
		}
	}

	.sp-table {
		overflow: auto !important;

		thead {
			position: sticky;
			top: 0;
		}
	}
}

.statuses-select {
	min-width: 200px;

	&.sp-dropdown-list {
		width: 300px;
	}
}

.popup-registry-filter {
	display: flex;
	justify-content: space-between;

	.order-btn {
		cursor: pointer;
		margin-right: 5px;

		&-disabled {
			pointer-events: none;
			color: #ccc;
		}
	}
}
