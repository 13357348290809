.techfires-module .techfires-note {

	.note-toolbar{
		display: flex;
		gap: 20px;
		margin-bottom: 15px;
	}

	table {
		border-collapse: separate;
		border-spacing: 0;

		th{
			border-bottom: 1px solid #888;
		}
		
		th, td {
			padding: 5px;
			background: #fff;
			border-top: 1px solid #888;
			border-left: 1px solid #888;
			
			&.vert {
				writing-mode: vertical-lr;
				transform: scale(-1);
			}

			&:last-child{
				border-right: 1px solid #888;
			}
		}

		thead{
			position: sticky;
			top: 0;

			.vert{
				border-top: none;
				border-left: none;
				border-right: 1px solid #888;
			}
			.b-b-n{
				border-bottom: none;
			}
			.b-r-n{
				border-right: none;
			}
		}
		
		tr {
			&.numbered, &.division-total td {
				background: #fff000;
			}

			&.head-org td {
				background: #0ad;
				text-align: center;
				font-weight: bold;
			}

			td.value {
				text-align: center;
			}

			&.firedep-note {
				td:first-child {
					background: #0f0;
				}
				
				&.empty td:first-child {
					background: #d00;
					color: #fff;
				}
			}
		}
	}
}
