.label-start {
	display: flex;
	width: 100%;
	align-items: center;
	gap: 5px;
	
	/*
	.vehicle-number {
		width: 70px;
		text-align: right;
		border: 1px solid rgba(#000, 0.2);
		border-radius: 3px;
		line-height: 18px;
		padding: 0 5px;
		font-size: 11px;
		background: #f4f8fc;
		font-weight: bold;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		.flag {
			width: 12px;
			height: 9px;
			margin-top: -1px;
			margin-left: 3px;
			background: #d00;
			filter: saturate(0.7);
			//opacity: 0.9;

			div:first-child {
				background: #ddd;
				height: 3px;
			}

			div:nth-child(2) {
				background: #00d;
				height: 3px;
			}
		}
	}
	*/

	.state-number {
		flex-grow: 1;
		text-align: right;
	}

	.call-sign {
		flex-grow: 1;
	}

	.vehicle-brand {
		flex-grow: 1;
	}
	
	.vehicle-type {
		border-radius: 3px;
		line-height: 18px;
		padding: 0 5px;
		display: flex;
		align-items: center;
		font-size: 12px;
		background: #d0d8e0;
		color: #333;
		border: 1px solid rgba(#000, 0.1);
	}
}

.label-end {
	width: 100%;
	display: flex;
	align-items: center;
	
	strong {
		font-weight: normal;
		flex-grow: 1;
	}
	
	em {
		flex-shrink: 0;
		text-align: right;
		font-style: normal;
		font-size: 11px;
		color: #0af;
	}
}
