@use "reset.css";
@use "hover.css";
@use "ui/index";
@use "modules.scss";

$primary: #37b813;
$primary-rgb: 55, 184, 19; // #37b813
$secondary: #1d46a1;
$secondary-rgb: 29, 70, 161; // #1D46A1
$light-blue-bg: #f2f6fc;
$bg: #ffffff;
$dark: #393e46;
$lighter-dark: #494e58;
$deep-dark: #2e3238;
$grey: #b8b8b8;
$dark-grey: #7a7a7a;
$light-grey: #eeeeee;
$lighter-grey: #f9f9f9;
$light-accent: #eff4f9;
$super-light-grey: #f9f9ff;
$selected-bg: #c2eaa7;
$orange-500: #f97316;
$red-500: #ef4444;
$error: #d00;
$success: #33ce65;
$danger: #cd4141;
$info: #2784ff;
$warning: #ff9900;

:root {
	--primary: #{$primary};
	--primary-rgb: #{$primary-rgb};
	--secondary: #{$secondary};
	--secondary-rgb: #{$secondary-rgb};
	--bg: #{$bg};
	--light-blue-bg: #{$light-blue-bg};
	--dark: #{$dark};
	--lighter-dark: #{$lighter-dark};
	--deep-dark: #{$deep-dark};
	--grey: #{$grey};
	--dark-grey: #{$dark-grey};
	--light-grey: #{$light-grey};
	--lighter-grey: #{$lighter-grey};
	--super-light-grey: #{$super-light-grey};
	--light-accent: #{$light-accent};
	--selected-bg: #{$selected-bg};
	--orange-500: #{$orange-500};
	--red-500: #{$red-500};
	--error: #{$error};
	--row-height: 40px;
	--ui-element-height: 36px;
	--element-height: 36px;
	--input-font-size: 14px;
	--white: #ffffff;
	--black: #393e46;

	--header-height: 80px;
	--sidebar-width: 90px;
	--sidebar-btn-size: 80px;

	--hover: var(--lighter-grey);
	--border: var(--grey);
	--border-radius: 5px;
	--text: var(--dark);
	--panel-bg: var(--bg);

	--header-z: 3000;
	--sidebar-z: 2000;
	--panel-z: 1000;

	--success: #{$success};
	--danger: #{$danger};
	--info: #{$info};
	--warning: #{$warning};

	// covid

	--confirmed: #d00;
	--ambulatory: #90d;
	--isolated: #acf;
	--recovered: #6a0;
	--contacted: #f80;
	--infdep: #d40;
	--observator: #80a;
	--self: #0af;

	//techfire

	--adpi-message: #{$orange-500};
	--_112-message: #{$red-500};
}

html,
body,
#root {
	width: 100%;
	height: 100%;
	background: var(--light-grey);
	display: flex;
	align-items: center;
	justify-content: center;
}

body {
	font-family: "Roboto", sans-serif;
	font-size: 12px;
	line-height: 1.4em;
	font-feature-settings: "lnum";
}

.login-layout {
	width: 100%;
	height: 100%;
	background: var(--content-bg, #f2f2f4);
	display: flex;
	align-items: flex-start;
	justify-content: center;
}

.error {
	display: block;
	margin: 10px 0;
	color: var(--danger);
}

a {
	color: var(--primary);

	&:hover {
		color: #000;
	}
}

hr {
	height: 1px;
	border: 0;
	background: #ddd;
	margin-bottom: 15px;
}

h1 {
	font-size: 16px;
	margin-bottom: 1em;
}

h2 {
	font-weight: bold;
	font-size: 18px;
	line-height: 21px;
	margin-bottom: 16px;
}

h3 {
	font-weight: 700;
	font-size: 14px;
	line-height: 16px;
	margin: 20px 0 30px 0;
}

.hint {
	color: var(--dark-grey);
}

a {
	text-decoration: none;
}

.login-layout {
	width: 80%;
	height: 80%;
	background: var(--main-bg, #fff);
	display: flex;

	.login-form {
		flex-grow: 1;
		padding: 30px 90px;
		height: 100%;

		.title {
			display: flex;
			align-items: center;

			svg {
				height: 50px;
				width: 50px;
			}

			span {
				margin-left: 20px;
				font-weight: 700;
				font-size: 16px;
				line-height: 19px;
				color: var(--grey);
				text-transform: uppercase;
			}
		}

		.welcome {
			margin: 40px 0;
			font-weight: 700;
			font-size: 48px;
			line-height: 56px;
		}

		.form-field {
			label {
				color: var(--dark-grey);
				font-size: 14px;
				line-height: 16px;
				margin-bottom: 8px;
			}

			input {
				max-width: 300px;
				min-width: 120px;
			}
		}

		.submit {
			margin: 20px 0;

			a {
				margin-left: 30px;
				text-decoration: none;
			}
		}

		.error {
			font-size: 11px;
			color: #a00;
			font-weight: 700;
			height: 50px;
		}
	}

	.login-description {
		background-color: var(--primary);
		width: 40%;
		height: 100%;
		padding: 120px 80px;

		.top,
		.modules-list {
			display: flex;
			flex-direction: column;
			color: #ffffff;
		}

		.top {
			font-weight: 700;
			font-size: 48px;
			line-height: 56px;
			margin-bottom: 16px;
		}

		.modules-list {
			margin-top: 80px;
			font-size: 18px;
			line-height: 25px;
		}
	}

	@media (min-width: 767px) and (max-width: 1199px) {
		text-align: center;
		width: 90% !important;

		.login-form {
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 30px !important;

			.title {
				margin: 0 auto;
			}

			.welcome {
				margin: 40px 0 !important;
			}
		}

		.login-description {
			padding: 50px !important;
			text-align: left !important;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
	}

	@media (max-width: 767px) {
		width: 100% !important;
		height: 100% !important;

		.login-form {
			width: 100% !important;
			padding: 40px !important;

			.title {
				span {
					font-size: 12px !important;
				}
			}

			input {
				max-width: unset !important;
			}

			.welcome {
				margin: 20px 0 !important;
				font-size: 26px !important;
				line-height: 40px !important;
			}
		}

		.login-description {
			display: none;
		}
	}
}

.cursor-pointer {
	cursor: pointer;
}

.overflow-auto {
	overflow: auto;
}

.text-link {
	text-decoration: underline;

	&:hover {
		color: #0af;
	}
}

input.monospaced {
	font-family: "Consolas", monospace;
}



.full-container {
	width: 100%;
	height: 100%;
	background: #fff;
	overflow: auto;
}

// нужны для автоматического вычисления perPage
.model-list,
.hasmany-relation-list {
	height: 100%;
	display: flex;
	flex-direction: column;

	#table {
		flex-grow: 1;
	}
}

#table {
	height: 100%;
}



.form-validation-error {
	color: #d00;
	font-weight: bold;
}

input.validation-error {
	border-width: 1.5px;
	border-color: var(--error);

	&:hover,
	&:focus {
		border-color: var(--error) !important;
	}
}

label {
	&.is-required {
		&:after {
			content: "*";
			position: absolute;
			color: var(--danger);
			font-weight: bold;
			margin-left: 2px;
			font-size: 16px;
		}
	}
}

.default-cursor {
	cursor: default;
}

.print-only {
	display: none;
}

@media print {
	@page :first {
		margin-top: -22%;
	}

	@page {
		size: landscape;
		margin-right: -50%;
		margin-left: -50%;
		margin-top: 0;
	}

	body {
		zoom: 50%;
	}

	.main-layout {
		padding: initial;
	}

	.operational-report-by-types {
		.main {
			display: flex;
			flex-direction: column;
		}
	}

	.fires-count-chart {
		width: 750px !important;
	}

	.fires-area {
		.fires-area-chart {
			width: 750px !important;

			canvas {
				width: 750px !important;
			}
		}
	}

	.main-sidebar,
	.main-header,
	.action-panel {
		display: none;
	}

	.fires-module {
		overflow: initial;
	}

	.print-only {
		display: initial;
	}
}

.warn-message {
	background: #fffcf0;
	padding: 10px;
	border: 1px dashed rgba(0, 0, 0, 0.2);

}

.danger-message {
	background: var(--danger);
	padding: 10px;
	border: 1px dashed rgba(0, 0, 0, 0.2);
	color: var(--white)
}