.image-input {
    position: relative;

    img {
        border-radius: 4px;
    }

    .clear {
        display: none;
        position: absolute;
        top: 5px;
        left: 5px;
        zoom: 0.75;

        border: none;
        background: var(--danger);
        color: var(--white);
        cursor: pointer;
        border-radius: 2px;


        --primary-bg: var(--danger);

    }

    &:hover {
        .clear {
            display: inline;
        }
    }
}