.techfires-page {
	.sp-tabs {
		display: flex;
		flex-direction: column;
		max-height: 100%;

		.sp-tabs-container {
			min-height: 36px;
		}

		.sp-tabs-content {
			display: flex;
			flex-direction: column;
			&:has(.techfire-card-page) {
				overflow: hidden;
			}
		}
	}
}
