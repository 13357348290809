.drag-and-drop-field {
    height: 200px;
    border: 2px dashed var(--grey);
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .dragReject {
        color: var(--danger);
    }

    .format {
        color: var(--primary)
    }

    &:hover,
    &.active {
        background-color: var(--light-grey);
    }

    &.disabled {
        pointer-events: none;
        cursor: default;
    }

    button {
        margin: 10px 0;
    }

    &.active {
        border-color: var(--primary);
    }
}