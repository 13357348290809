.techfire-card-form {
	display: flex;
	flex-direction: row;
	gap: 24px;
	overflow: auto;
	height: 100%;

	.left {
		display: flex;
		max-width: calc(1224px - 570px);
		flex-direction: column;
		gap: 24px;

		.card-left-row {
			align-items: center;
			width: 100%;

			.left-row-field {
				min-width: 490px;
			}

			button {
				margin-top: 12px;
			}
		}
	}
	.right {
		.techfire-create-map {
			height: 420px;
			width: 472px;
		}

		.map {
			border-radius: 4px;
			border: 1px solid var(--light-grey);
		}
	}
}
