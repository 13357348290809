.hasmany-relation-list  {

    .range-filters {
        padding-top: 20px;

    }
    .pager {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        .ui-pager {
            margin-top: 0;
            margin-bottom: 0;
        }

        h3 {
           margin: 0;
        }
        margin: 15px 0;
    }
}