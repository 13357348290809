.notifications {
	display: flex;
	flex-direction: column;
	gap: 10px;
	position: fixed;
	top: 50px;
	right: 50px;

	z-index: 5000;
	font-size: 14px;
	align-items: flex-end;

	.notification {
		padding: 12px 16px;
		border-radius: 4px;
		color: var(--white);
		width: max-content;
		max-width: 500px;
		cursor: pointer;
		animation: notification 10s !important;

		&-animation-basic {
			@keyframes notification {
				0% {
					opacity: 0;
				}
				25% {
					opacity: 1;
				}
				75% {
					opacity: 1;
				}
				100% {
					opacity: 0;
				}
			}
		
		}

		&-animation-no-fade-out {
			animation: noFadeOutNotification 1s;
			
			@keyframes noFadeOutNotification {
				0% {
					opacity: 0;
				}
				100% {
					opacity: 1;
				}
			}
	
		}

		& > p {
			margin: 0;
		}

		&-success {
			background-color: var(--success);
		}

		&-error {
			background-color: var(--error);
		}
		&-danger {
			background-color: var(--danger);
		}

		&-warning {
			background-color: var(--warning);
		}
		.controls {
			display: flex;
			gap: 12px;
			width: 100%;
			margin-top: 12px;

			button {
				margin: 0;
				border: none;
			}

			.reject {
				background-color: rgba(255, 255, 255, 0.2);
				color: var(--bg);
			}

			.open {
				color: var(--dark);
			}
		}
	}
}
