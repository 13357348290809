.emergency-reserves {
	&>.content {
		display: flex;
		column-gap: 20px;
		margin-top: 20px;

		.ui-pager {
			margin-top: unset;
		}

		.categories {
			padding: 5px 16px;
			background-color: #fff;
			border-radius: 0 0 8px 8px;
			width: 450px;

			.caterory-item {
				display: flex;
				justify-content: space-between;
				padding: 15px 0;
				font-weight: 700;
			}

			.sub-caterory-item {
				display: flex;
				justify-content: space-between;
				padding: 5px 0;
				font-weight: 500;
				font-size: 12px;
			}

			.checkbox {
				display: flex;

				.checkbox-toggle {
					display: inline-block;
					vertical-align: middle;
					margin-right: 10px;
					margin-top: -2px;
					background: #e7e7ed;
					border: 1px solid #e7e7ed;
					border-radius: 5px;
					min-width: 20px;
					height: 20px;
					line-height: 14px;
					text-align: center;
					cursor: pointer;

					svg {
						position: relative;
						color: var(--primary);
						width: 18px;
						height: 18px;
						transform: scale3d(0.8, 0.8, 0.8);
					}
				}
			}

			.filter-arrow {
				flex-basis: 20px;
				flex-shrink: 0;
				text-align: center;
				border-top-right-radius: 3px;
				border-bottom-right-radius: 3px;
				color: var(--dropdown-arrow-color);
				transition: transform 250ms;
			}

			.category-block:not(:last-child) {
				border-bottom: 1px solid #e7e7ed;
			}
		}
	}
}