.fire-card-page {
    .fire-actionbar {
        padding-bottom: 15px;
        border-bottom: 2px solid var(--light-grey);
        margin-bottom: 15px;
        align-items: center;

        .custom-controls {
            gap: 10px !important;

            label {
                margin-bottom: 1px;
            }

            .last-edit {
                margin-left: 20px;
                margin-bottom: 0;
            }
        }
    }
}